import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer, inject } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { formatDateHour } from 'utils'
import classNames from 'classnames'
import { compose } from 'recompose'
import Tooltip from 'components/Tooltip/Tooltip'
import { path } from 'ramda'

const Row = ({ data }) => {
  let value = ''

  if (data.type === 'date' && data.value !== '-') {
    // HOTFIX back end is not consistant with date
    if (typeof data.value === 'object') {
      value = formatDateHour({ date: data.value.date })
    } else {
      value = formatDateHour({ date: data.value })
    }
  } else value = data.value

  return (
    <div className="no-gutters col-12 col-md-6 col-lg-3">
      <span className="label text-primary">{data.label}</span>
      <br />
      <span className="value" name={data.key}>
        {value}
      </span>
    </div>
  )
}

const Card = ({
  data: { wan },
  UserStore: { isExpert, isClaimManager },
  data,
  DashboardCtrl: { headersField, headersInfo, addRemoveMissionPrint, missionsToPrint },
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const goTo = () => {
    if (isExpert) {
      history.push(`/mission/${wan}/information`)
    } else if (isClaimManager) {
      history.push(`/managerClaim/${wan}/calculation`)
    } else {
      history.push(`/claim/${wan}/information`)
    }
  }

  const progress = []
  const dataHeaderFiled = headersField.map(field => {
    const dataField = {
      key: field,
      label: headersInfo[field].label,
      type: headersInfo[field].type,
    }
    if (data[field]) dataField.value = data[field]
    else dataField.value = '-'
    return dataField
  })

  for (let i = 1; i <= data['step.total']; i++) {
    if (i <= data['step.current']) {
      progress.push({ orange: true })
    } else {
      progress.push({ orange: false })
    }
  }

  const expertiseKey = isExpert
    ? path(['expertiseType.key'], data)
    : path(['mission.missionInsurerInformation.expertiseType.key'], data)
  const expertiseValue = isExpert
    ? path(['expertiseType.value'], data)
    : path(['mission.missionInsurerInformation.expertiseType.value'], data)

  return (
    <div className="card-mission clickable mt-2 p-2" onClick={goTo}>
      <div className="row no-gutters">
        {data['tags'] && data['tags'].includes('NEW') ? (
          <div className="new-tag">{t('dashboard.new')}</div>
        ) : null}
        <div className="col-12 col-md-2 col-lg-1 text-left mt-3">
          <div className="row no-gutters justify-content-center">
            {progress.map((rowData, index) => (
              <i
                key={index}
                className={classNames('fa', {
                  'fa-circle': rowData.orange,
                  'fa-circle-o': !rowData.orange,
                })}
              />
            ))}
            <br />
          </div>
          {data['tags'] && data['tags'].includes('URGENT') ? (
            <div className="row no-gutters justify-content-center mt-2">
              <i className={classNames('fa fa-warning')} />
            </div>
          ) : null}
          {data['tags'] && data['tags'].includes('WORK_CFA') ? (
            <div className="row no-gutters justify-content-center mt-2">
              <i className={classNames('fa fa-briefcase')} />
            </div>
          ) : null}
          {expertiseKey && expertiseKey === 'GROUND_EXPERTISE_TYPE' && (
            <div className="row no-gutters justify-content-center mt-2">
              <Tooltip text={expertiseValue}>
                <i className={classNames('fa fa-home')} />
              </Tooltip>
            </div>
          )}
          {expertiseKey && expertiseKey === 'TELE_EXPERTISE_TYPE' && (
            <div className="row no-gutters justify-content-center mt-2">
              <Tooltip text={expertiseValue}>
                <i className={classNames('fa fa-mobile')} />
              </Tooltip>
            </div>
          )}
          {expertiseKey && expertiseKey === 'VISIO_EXPERTISE_TYPE' && (
            <div className="row no-gutters justify-content-center mt-2">
              <Tooltip text={expertiseValue}>
                <i className={classNames('fa fa-video-camera')} />
              </Tooltip>
            </div>
          )}
        </div>
        <div className="col-12 col-md-10 col-lg-11">
          <div className="row no-gutters">
            {dataHeaderFiled.map((rowData, index) => (
              <Row key={`row-${wan}-${index}`} data={rowData} />
            ))}
          </div>
        </div>
      </div>
      <input
        type="checkbox"
        className="form-check-input print-mission mr-4"
        checked={missionsToPrint.some(id => id === wan)}
        onChange={e => addRemoveMissionPrint(wan, e.target.checked)}
        onClick={e => e.stopPropagation()}
        disabled={data['tags'] && data['tags'].includes('WORK_CFA')}
      ></input>
    </div>
  )
}

export default compose(
  inject('UserStore', 'DashboardCtrl'),
  observer,
)(Card)
