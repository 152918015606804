import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { isNil, path } from 'ramda'
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react'

import './Stream.css'
import PageLayout from 'components/PageLayout/PageLayout'

const Teleexpertise = ({ TeleexpertiseCtrl: { endSession } }) => {
  const [publisherVideo, setPublisherVideo] = useState(true)
  const [publisherAudio, setPublisherAudio] = useState(true)
  const [streamConnected, setStreamConnected] = useState(false)
  const [endCall, setEndCall] = useState(false)
  const params = useParams()
  const { t } = useTranslation()
  const sessionId = path(['sessionId'], params)
  const token = path(['token'], params)

  if (isNil(sessionId) || isNil(token)) {
    return (
      <div className="stream d-flex flex-column align-items-center">
        <p>{t('teleexpertise.sessionError')}</p>
      </div>
    )
  } else if (endCall) {
    endSession()
    return (
      <div className="stream d-flex flex-column align-items-center">
        <p>{t('teleexpertise.callEnded')}</p>
      </div>
    )
  }
  return (
    <div className="page-teleexpertise">
      <h5 className="text-center mt-3 mb-3">
        {streamConnected ? (
          <span className="text-success">{t('teleexpertise.session')}</span>
        ) : (
          <span className="text-warning">{t('teleexpertise.waitingForExpertConnection')}</span>
        )}
      </h5>
      <div className="stream d-flex flex-column align-items-center">
        <OTSession
          apiKey="47345661"
          sessionId={sessionId}
          token={token}
          eventHandlers={{
            streamCreated: () => {
              setStreamConnected(true)
            },
            streamDestroyed: () => {
              setStreamConnected(false)
            },
          }}
        >
          <OTPublisher
            properties={{
              insertDefaultUI: true,
              showControls: true,
              fitMode: 'cover',
              facingMode: 'environment',
              mirror: false,
              publishVideo: true,
              publishAudio: publisherAudio,
              width: 640,
              height: 480,
            }}
          />

          <OTStreams>
            <OTSubscriber
              properties={{
                insertDefaultUI: true,
                showControls: true,
                width: 0,
                height: 0,
                publishVideo: false,
                publishAudio: true,
              }}
            />
          </OTStreams>
        </OTSession>

        <div className="my-3">
          <div className="btn-group">
            <button
              className={classNames('btn', {
                'btn-success': publisherVideo,
                'btn-danger': !publisherVideo,
              })}
              onClick={() => {
                setPublisherVideo(!publisherVideo)
              }}
            >
              <i className="fa fa-video-camera" />
            </button>

            <button
              className={classNames('btn', {
                'btn-success': publisherAudio,
                'btn-danger': !publisherAudio,
              })}
              onClick={() => {
                setPublisherAudio(!publisherAudio)
              }}
            >
              <i
                className={classNames('fa', {
                  'fa-microphone': publisherAudio,
                  'fa-microphone-slash': !publisherAudio,
                })}
              />
            </button>

            <button
              className="btn btn-danger"
              onClick={() => {
                setEndCall(true)
                endSession()
              }}
            >
              {t('teleexpertise.endSession')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default compose(
  PageLayout,
  inject('TeleexpertiseCtrl'),
  observer,
)(Teleexpertise)
