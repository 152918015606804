import React, { useState, useRef, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { isNil } from 'ramda'
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react'

import './Stream.css'
import Button from 'components/Button/Button'
import ScreenshotModal from './ScreenshotModal'
import Loader from 'components/Loader/Loader'

const Expert = ({ TeleexpertiseCtrl: { endSession, openScreenshotModal } }) => {
  const [publisherAudio, setPublisherAudio] = useState(true)
  const [streamConnected, setStreamConnected] = useState(false)
  const [endCall, setEndCall] = useState(false)
  const subscriber = useRef(null)
  const { wan, sessionId, token } = useParams()
  const { t } = useTranslation()
  const [counter, setCounter] = useState(2400)

  function increase() {
    setCounter(1200)
  }

  function secondsToHms(d) {
    d = Number(d)
    var m = Math.floor((d % 3600) / 60)
    var s = Math.floor((d % 3600) % 60)

    var mDisplay = m > 0 ? m + ' m ' : ''
    var sDisplay = m === 0 ? s + ' s' : ''
    return mDisplay + sDisplay
  }

  useEffect(() => {
    let intervalId

    if (streamConnected) {
      if (counter > 0) {
        intervalId = setInterval(() => {
          setCounter(counter => counter - 1)
        }, 1000)
      } else {
        setEndCall(true)
        setStreamConnected(false)
      }
    }

    return () => clearInterval(intervalId)
  }, [streamConnected, counter])

  const addScreenshot = () => {
    if (subscriber && subscriber.current) {
      const sub = subscriber.current.getSubscriber()
      const imgData = sub.getImgData()

      openScreenshotModal(imgData)
    }
  }

  if (isNil(sessionId) || isNil(token)) {
    return (
      <div className="stream d-flex flex-column align-items-center">
        <p className="mt-4">{t('teleexpertise.sessionError')}</p>
      </div>
    )
  } else if (endCall) {
    endSession()
    return (
      <div className="stream d-flex flex-column align-items-center">
        <p className="mt-4">{t('teleexpertise.callEnded')}</p>
      </div>
    )
  }
  return (
    <div className="page-expert-visio">
      <h5 className="text-center mt-3 mb-3">
        {streamConnected ? (
          <span className="text-success">{t('teleexpertise.session')}</span>
        ) : (
          <span className="text-warning">{t('teleexpertise.waitingForConnection')}</span>
        )}
      </h5>
      {!streamConnected && <Loader />}

      <ScreenshotModal wan={wan} />

      <div className="stream d-flex flex-column align-items-center">
        <OTSession
          apiKey="47345661"
          sessionId={sessionId}
          token={token}
          eventHandlers={{
            streamCreated: () => {
              setStreamConnected(true)
            },
            streamDestroyed: () => {
              setStreamConnected(false)
            },
          }}
        >
          <OTPublisher
            properties={{
              insertDefaultUI: true,
              showControls: true,
              publishVideo: false,
              publishAudio: publisherAudio,
              width: 0,
              height: 0,
            }}
          />

          <OTStreams>
            <OTSubscriber
              ref={subscriber}
              properties={{
                insertDefaultUI: true,
                showControls: true,
                fitMode: 'cover',
                width: 640,
                height: 480,
                publishVideo: true,
                publishAudio: true,
              }}
            />
          </OTStreams>
        </OTSession>

        {streamConnected && (
          <div className="my-3 text-center">
            <div className="btn-group">
              <Button
                className="btn btn-primary"
                disabled={isNil(subscriber)}
                onClick={addScreenshot}
              >
                <i className="fa fa-camera" />
              </Button>

              <button
                className={classNames('btn', {
                  'btn-success': publisherAudio,
                  'btn-danger': !publisherAudio,
                })}
                onClick={() => {
                  setPublisherAudio(!publisherAudio)
                }}
              >
                <i
                  className={classNames('fa', {
                    'fa-microphone': publisherAudio,
                    'fa-microphone-slash': !publisherAudio,
                  })}
                />
              </button>

              <button
                className="btn btn-danger"
                onClick={() => {
                  setEndCall(true)
                  setStreamConnected(false)
                  endSession()
                }}
              >
                {t('teleexpertise.endSession')}
              </button>
            </div>
            <div>Il vous reste {secondsToHms(counter)} de visio-expertise</div>
            {counter < 120 && (
              <div>
                <button onClick={increase} className="btn btn-success">
                  Prolonger la visio-expertise
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default inject('TeleexpertiseCtrl')(observer(Expert))
